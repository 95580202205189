import {html, LitElement, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import Styles from "./legend.lit.scss";
import {pinIcon} from "./pinIcon";

@customElement("eop-google-maps-legend-item")
export class EopGoogleMapsLegendItem extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: "color"})
    public color: string;

    @property({attribute: "label"})
    public label: string;

    public render(): TemplateResult {
        return html`
            <div class="legend-item">
                <span class="icon">${pinIcon(this.color, {width: 25, height: 28, scale: 1.1}, (color) => this.getComputedColor(color))}</span>
                <span class="label">${this.label}</span>
            </div>
        `;
    }

    private getComputedColor(propertyName: string): string {
        return getComputedStyle(this).getPropertyValue(propertyName);
    }

}