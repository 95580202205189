import type {PropertyMap} from "../../common/utils/objects";

export const COLOR_WHITE = "--marker-color-white";
export const COLOR_PRIMARY = "--marker-color-primary";
export const COLOR_PRIMARY_HIGHLIGHT = "--marker-color-primary-highlight";
export const COLOR_SECONDARY = "--marker-color-secondary";
export const COLOR_SECONDARY_HIGHLIGHT = "--marker-color-secondary-highlight";
export const COLOR_GRAY = "--marker-color-gray";
export const COLOR_GRAY_HIGHLIGHT = "--marker-color-gray-highlight";

export const MARKER_COLOR_PROPS: PropertyMap<string> = {
    primary: COLOR_PRIMARY,
    secondary: COLOR_SECONDARY,
    gray: COLOR_GRAY
};