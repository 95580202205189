import {COLOR_GRAY_HIGHLIGHT, COLOR_PRIMARY_HIGHLIGHT, COLOR_SECONDARY_HIGHLIGHT} from "./colors";
import {customElement, property, query} from "lit/decorators.js";
import {UnLitElement} from "../../common/elements";
import {GoogleMapsFactory} from "./googleMapsFactory";
import {resolve} from "../../container";
import {BOOTSTRAP} from "../../common/resolutionConstants";
import {Resolution} from "../../common/resolution";
import {pinIcon} from "./pinIcon";

@customElement("eop-google-maps-poi")
export class EopGoogleMapsPoi extends UnLitElement {
    @property({attribute: "visual-data-title"})
    public title: string;
    @property({attribute: "color"})
    private color: string;
    @property({attribute: "lat", type: Number})
    private lat: number;
    @property({attribute: "lng", type: Number})
    private lng: number;
    @query(".close-button")
    private closeButton: HTMLElement;

    public marker: google.maps.marker.AdvancedMarkerElement;

    public constructor(
        private googleMapsFactory: GoogleMapsFactory = resolve(GoogleMapsFactory),
        private resolution: Resolution = resolve(Resolution)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.closeButton.addEventListener("click", () => this.close());
    }

    public renderOnMap(map: google.maps.Map): google.maps.marker.AdvancedMarkerElement {
        this.marker = this.googleMapsFactory.createMarker({
            map: map,
            position: this.position(),
            title: this.title,
            content: pinIcon(this.color, {width: 30, height: 35, scale: 1.5}, (colorProp) => this.getComputedColor(colorProp))
        });
        return this.marker;
    }

    public position(): google.maps.LatLng {
        return this.googleMapsFactory.createLatLng(this.lat, this.lng);
    }

    public isOpen(): boolean {
        return this.classList.contains("open");
    }

    public toggleOpen(): void {
        this.isOpen() ? this.close() : this.open();
    }

    public open(): void {
        this.classList.add("open");
        if (!this.marker) {
            return;
        }
        this.marker.content = pinIcon(this.color, {width: 30, height: 35, scale: 1.5}, (colorProp) => this.getComputedColor(colorProp), this.getFillColor());
        this.toggleMapOptions(false);
    }

    public close(): void {
        this.classList.remove("open");
        if (!this.marker) {
            return;
        }
        this.marker.content = pinIcon(this.color, {width: 30, height: 35, scale: 1.5}, (colorProp) => this.getComputedColor(colorProp));
        this.toggleMapOptions(true);
    }

    private getFillColor(): string {
        return this.color === "primary" ? COLOR_PRIMARY_HIGHLIGHT :
            this.color === "gray" ? COLOR_GRAY_HIGHLIGHT
                : COLOR_SECONDARY_HIGHLIGHT;
    }

    private toggleMapOptions(switchedOn: boolean): void {
        this.marker.map?.setOptions({mapTypeControl: switchedOn});
        if (switchedOn || this.resolution.upTo(BOOTSTRAP.XS)) {
            this.marker.map?.setOptions({zoomControl: switchedOn, fullscreenControl: switchedOn, streetViewControl: switchedOn});
        }
    }

    private getComputedColor(propertyName: string): string {
        return getComputedStyle(this).getPropertyValue(propertyName);
    }
}