import {COLOR_PRIMARY, COLOR_WHITE, MARKER_COLOR_PROPS} from "./colors";
import {elementFrom} from "../../common/utils/html";

export function pinIcon(
    color: string,
    options: { width: number, height: number, scale: number },
    computedColorResolver: (color: string) => string,
    fillColor?: string
): Element {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" 
                                    width="${options.width}px" height="${options.height}px" viewBox="0 0 ${options.width + 5} ${options.height + 5}" 
                                    fill="${computedColorResolver(fillColor ?? MARKER_COLOR_PROPS[color] ?? COLOR_PRIMARY)}"
                                    fill-opacity="1"
                                    fill-rule="evenodd"
                                    stroke="${computedColorResolver(COLOR_WHITE)}" 
                                    stroke-width="2"
                                    stroke-opacity="1"
                                 >
                                    <g>
                                        <path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" transform="scale(${options.scale})"></path>
                                    </g>
                                 </svg>`;
    return elementFrom(svg);
}